import axios from 'axios'

/* 校验是否企业微信用户 */
// export function checkUser(accessToken, code) {
//   return axios.get('wechat/checkUser?accessToken=' + accessToken + "&code=" + code)
// }

export function checkUser(userId) {
  return axios.get('wechat/checkUser?userId=' + userId)
}


