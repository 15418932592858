import router from './router'
import store from './store'
// import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
// import getPageTitle from '@/utils/get-page-title'
// import { getToken } from './utils/auth'
import {
  Storage
} from '@/utils/localStorage'
import {
  checkUser
} from '@/api/user'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  // document.title = getPageTitle(to.meta.title)

  // 1、截取url后拼接的用户id 2、调用接口，根据用户id查询用户信息是否存在：若存在，授权访问；不存在，跳转404
  // url示例：http://localhost:8080/mobile?accessToken=XXX&code=XXX
  let dz_url = window.location.href
  let userId
  if (dz_url.split('?')[1]) {
    let cs_after = dz_url.split('?')[1] //获取?之后的参数字符串
    if (cs_after.split('&').length) {
      let cs_arr = cs_after.split('&') //参数字符串分割为数组
      let cs = {}
      for (let i = 0; i < cs_arr.length; i++) { //遍历数组，拿到json对象
        cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      userId = cs.userId
    }
  }
  console.log('-------userId:' + userId)

  if (to.meta.needAuth) {
    if (userId) {
      if (localStorage.getItem('auth')) {
        localStorage.removeItem('auth')
        next()
      }
      const res = await checkUser(userId)
      console.log('-------code:' + res.data.code + ',' + new Date())
      if (res.data.code === 20000) {
        localStorage.setItem('auth', 1)
        next({
          ...to
        })
      } else {
        localStorage.removeItem('auth')
        next('/404')
      }
    } else {
      localStorage.removeItem('auth')
      next('/404')
    }

  } else {
    localStorage.removeItem('auth')
    next()
  }

  NProgress.done()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
