<template>
  <!-- 项目综合排名 -->
  <div class="mobilepanel">
    <div class="head" style="margin-bottom: 10px">
      <slot></slot>
      <div class="lefttitle" style="    margin-right: .5rem;">综合排名</div>
      <!--      <div class="leftimg"></div>-->
    </div>
    <div class="chartinfo">
      <div class="xmpm">
        <div class="xm">
          <div class="xm1">
            <img src="../../assets/image/jx.png" alt=""/>
            <div class="xmm" v-text="xjlzyxm"></div>
            <span>现金流最优项目</span>
          </div>
        </div>
        <div class="xm">
          <div class="xm2">
            <img src="../../assets/image/jx.png" alt=""/>
            <div class="xmm" v-text="xyzyxm"></div>
            <span>效益最优项目</span>
          </div>
        </div>
        <div class="xm">
          <div class="xm3">
            <img src="../../assets/image/jx.png" alt=""/>
            <div class="xmm" v-text="fdxlzyxm"></div>
            <span>发电效率最优项目</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 现金流最优项目
      xjlzyxm: '',
      // 效益最优项目
      xyzyxm: '',
      // 发电效率最优项目
      fdxlzyxm: '',
    }
  },
  components: {
    //在此处注册
  },
  //钩子函数
  mounted () {
    //获取综合排名数据接口
    this.getzhpm()
  },
  computed: {},
  methods: {
    //获取综合排名数据接口
    getzhpm () {
      var _this = this
      this.axios
        .get('financial/getProjectRankingCashFlow')
        .then(response => {
          const data = response.data
          this.xjlzyxm = data[0].xmmc
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => (this.loading = false))
      this.axios
        .get('financial/getProjectRankingBenefit')
        .then(response => {
          const data = response.data
          this.xyzyxm = data[0].xmmc
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => (this.loading = false))
      this.axios
        .get('financial/getProjectRankingGenerationEfficiency')
        .then(response => {
          const data = response.data
          this.fdxlzyxm = data[0].xmmc
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>
