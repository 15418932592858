import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/js/jquery.js'
import './assets/js/flexible.js'
import './assets/css/index.css'
import './assets/css/mobile.css'
import './assets/js/InFoBox.js'
import './assets/css/swiper.css'
import './assets/js/swiper.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import echarts from 'echarts'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/permission' // permission control

Vue.use(VueAxios, axios)
Vue.use(ElementUI)
//全局域名
// axios全局配置
// 开发环境
// axios.defaults.baseURL = 'http://127.0.0.1:8886/'
// 测试环境
// axios.defaults.baseURL = 'http://172.16.227.109:8886/'
// 生产环境 10.0.8.75
axios.defaults.baseURL = 'http://m.greenenergy.chinaconch.com:8886/'
// 无缝滚动插件
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
//or you can set componentName default componentName is vue-seamless-scroll
Vue.use(scroll, {
  componentName: 'scroll-seamless'
})


Vue.prototype.$echarts = echarts
Vue.prototype.$store = store
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
