<template>
  <!-- 财务概况 -->
  <div class="mobilepanel pie">
    <div class="head" style="margin-bottom: 10px">
      <slot name="left"></slot>
      <div class="lefttitle">财务概况</div>
      <slot name="right"></slot>
      <!--      <div class="leftimg"></div>-->
    </div>
    <div class="chartinfo newChartInfo">
      <div class="profitList">
        <div class="profitList-item">
          <ul>
            <li>光伏电站: <span>{{ FinancialData.gfdz }}座</span></li>
            <li>储能电站: <span>{{ FinancialData.cndz }}座</span></li>
            <li>投资总额: <span>{{ FinancialData.tzze }}万元</span></li>
            <li>公司总资产: <span>{{ FinancialData.gszzc }}万元</span></li>
            <li>资产负债率: <span>{{ FinancialData.zcfzl }}</span></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 柱状图 -->
    <div class="mkcontain">
      <div class="chartinfo">
        <div class="charthead">
          <div class="charttitle">资产分布 单位：万元</div>
        </div>
        <div id="container" style="height: 80%" class="chart"></div>
      </div>
    </div>

    <!-- 柱状图 -->
    <div class="mkcontain">
      <div class="chartinfo">
        <div class="charthead">
          <div class="charttitle">资产分布 单位：万元</div>
        </div>
        <div id="container2" style="height: 80%" class="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/pie')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/legend')

import Highcharts from 'highcharts/highstock'
import Highcharts3D from 'highcharts/highcharts-3d'

console.log(Highcharts)
Highcharts3D(Highcharts)

export default {
  components: {},
  data () {
    return {
//echarts图表
      myChart1: '',
//echarts图表
      myChart2: '',
      chart: '',
      chart2: '',
      FinancialData: {}
    }
  },
//定义swiper对象
  computed: {},
//钩子函数
  mounted () {
    this.mychartinit()
    this.gettzqs()
  },
  methods: {
//获取财务概况大屏数据接口
    gettzqs () {
      var _this = this
      this.axios
        .get('financial/getFinancialOverview')
        .then((response) => {
          var line = []
          var line1 = []
          for (var i = 0; i < response.data.length; i++) {
            line.push(response.data[0].cn)
            line.push(response.data[0].fbgf)
            line.push(response.data[0].jzgf)

            line1.push(response.data[0].xjjyp)
            line1.push(response.data[0].yszk)
            line1.push(response.data[0].qtyszk)
            line1.push(response.data[0].gdzc)
            line1.push(response.data[0].qtfldzc)
          }
          _this.mychart1(_this.myChart1, line)
          _this.mychart2(_this.myChart2, line1)
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => (this.loading = false))
      this.axios
        .get('financial/getMobileFinancialOverview')
        .then((response) => {
          this.FinancialData = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
//图标初始化
    mychartinit () {
// // 初始化echarts实例
//       this.myChart1 = echarts.init(document.getElementById('echartcwgk1'))
//       this.myChart2 = echarts.init(document.getElementById('echartcwgk2'))
//       var _this = this
// // 4. 让图表跟随屏幕自动的去适应
//       window.addEventListener('resize', function () {
//         _this.myChart1.resize()
//         _this.myChart2.resize()
//       })
    },
    mychart1 (myChart1, getsjjgrs) {

      //职称结构取数
      var getsjjg = ['储能', '分布光伏', '集中光伏\u3000\u3000']
      // var getsjjgrs = [305, 436, 794];
      const syjgdata = []
      for (var i = 0; i < getsjjg.length; i++) {
        syjgdata.push({
          name: getsjjg[i],
          y: Math.floor(+getsjjgrs[i])
        })
      }
      if (this.chart) {
        this.chart.destroy()
      }
      // 初始化 Highcharts 图表
      this.chart = new Highcharts.Chart('container', {
        colors: ['#69f4d1', '#fede29', '#056eed'],
        chart: {
          type: 'pie',
          backgroundColor: 'rgba(0,0,0,0)',
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
          }
        },
        title: {
          text: ''
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            // innerSize: 80,
            dataLabels: {
              enabled: true,
              color: '#fff',
              format: '<b>{point.name}</b> </br> {point.y}'
            }
          }
        },
        series: [
          {
            type: 'pie',
            name: '',
            data: syjgdata
          }
        ],
        credits: {
          enabled: false
        },
      })
    },
    mychart2 (myChart2, getsjjgrs) {
//职称结构取数
      var getsjjg = [
        '现金及银票',
        '应收账款',
        '其他应收款',
        '固定资产',
        '其他非流动资产',
      ]
// var getsjjgrs = [305, 436, 400, 900, 200];
      var syjgdata = []
      for (var i = 0; i < getsjjg.length; i++) {
        syjgdata.push({
          name: getsjjg[i],
          y: Math.floor(+getsjjgrs[i])
        })
      }
      this.chart2 = new Highcharts.Chart('container2', {
        // colors: ['#69f4d1', '#fede29', '#056eed', '#9fe080', '#ee6666'],
        colors: ['#69f4d1', '#fede29', '#056eed', '#6f7de3', '#c257F6', '#f0fe29'],
        chart: {
          type: 'pie',
          backgroundColor: 'rgba(0,0,0,0)',
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
          }
        },
        title: {
          text: ''
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            dataLabels: {
              enabled: true,
              color: '#fff',
              format: '<b>{point.name}</b> </br> {point.y}'
            }
          }
        },
        series: [
          {
            type: 'pie',
            name: '',
            data: syjgdata,
            label: {
              formatter: params => {
                return (
                  '{name|' + params.name + '}\n{value|' + params.value + '}'
                )
              }
            }
            //       label: {
            //         normal: {
            //           formatter: params => {
            //             return (
            //               "{name|" + params.name + "}\n{value|" + params.value + "}"
            //             );
            //           },
            //           rich: rich,
            //           color: function(params) {
            //             return colorList[params.dataIndex];
            //           },
            //           padding: [0, -5, 0, -5]
            //         }
            //       },
          }
        ],
        credits: {
          enabled: false
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
ul, li {
  list-style: none;
}

.newChartInfo {
  background-size: 100% 100% !important;
  display: flex;
  align-items: center;
  padding-top: 0 !important;
  height: 3.5rem !important;
}

.profitList {
  font-size: 15px;
  color: #fff;
  margin-left: 20px;

  .profitList-title {
    color: skyblue;
    font-size: 18px;
  }

  .profitList-item {
    ul {
      li {
        margin-left: 50px;

        span {
          margin-left: 20px;
          color: rgb(255, 226, 10);
        }
      }
    }
  }
}
</style>
