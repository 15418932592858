<template>
  <div class="mobiletopcontent">
    <div class="topbox">
      <div class="topboxtop">
        {{ tzgllist[0].bntz }}<span style="font-size: 0.3rem">万元</span>
      </div>
      <div class="topboxbottom">本年投资</div>
    </div>
    <div class="topbox">
      <div class="topboxtop">
        {{ tzgllist[0].tzjd }}<span style="font-size: 0.35rem">%</span>
      </div>
      <div class="topboxbottom">投资进度</div>
    </div>
    <div class="topbox">
      <div class="topboxtop">
        {{ tzgllist[0].ljtz }}<span style="font-size: 0.3rem">万元</span>
      </div>
      <div class="topboxbottom">累计投资</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tzgllist: [
        {
          bntz: "",
          tzjd: "",
          ljtz: "",
        },
      ],
    };
  },
  //钩子函数
  mounted() {
    this.getdtzyxx1();
  },
  methods: {
    //地图中央信息接口
    getdtzyxx1() {
      var _this = this;
      this.axios
        .get("financial/getMapCenterInformation")
        .then((response) => {
          _this.tzgllist = response.data.tzgllist;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>