import Vue from 'vue'
import Router from 'vue-router'
import mobile from '../views/mobile.vue'
import error from '../views/404.vue'
Vue.use(Router)

const routes = [
  {
    path: '/mobile',
    name: 'mobile',
    component: mobile,
    meta: {
      needAuth: true
    },
  },
  {
    path: '/404',
    name: '404',
    component: error,
    meta: {
      needAuth: false
    },
  },
  {
    path: '*',
    redirect: '/404'
  },
]
const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes
})

const router = createRouter()

export default router
