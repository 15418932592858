<template>
  <!-- 财务概况 -->
  <div class="mobilepanel pie">
    <div class="head">
      <div class="lefttitle">运营动态</div>
      <!--      <div class="leftimg"></div>-->
    </div>
    <div class="chartinfo newChartInfo">
      <div class="profitList">
        <div class="profitList-item">
          <ul>
            <li>光伏电量: <span>{{ OperationData.gfdl }}亿度</span></li>
            <li>储能电量: <span>{{ OperationData.cndl }}万度</span></li>
            <li>售电电量: <span>{{ OperationData.sddl }}亿度</span></li>
            <li>实际收入: <span>{{ OperationData.sxsr }}万元</span></li>
            <li>盈利: <span>{{ OperationData.yl }}万元</span></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 发电量 -->
    <div class="mkcontain">
      <div class="chartinfo">
        <div
          id="echartyyzbfdl"
          style="height: 100%; margin-top: -0.5rem"
          class="chart"
        ></div>
      </div>
    </div>
    <!-- 收入 -->
    <div class="mkcontain">
      <div class="chartinfo">
        <div
          id="echartyyzbsrqk"
          style="height: 100%; margin-top: -0.5rem"
          class="chart"
        ></div>
      </div>
    </div>

    <!-- 利润 -->
    <div class="mkcontain">
      <div class="chartinfo">
        <div
          id="echartyyzblrqk"
          style="height: 100%; margin-top: -0.5rem"
          class="chart"
        ></div>
      </div>
    </div>
    <!-- 度电盈利 -->
    <div class="mkcontain">
      <div class="chartinfo">
        <div
          id="echartyyzbddyl"
          style="height: 100%; margin-top: -0.5rem"
          class="chart"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/pie')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/legend')
export default {
  components: {},
  data () {
    return {
      //echarts图表发电量
      myChart1: '',
      //echarts图表收入
      myChart2: '',
      //echarts图表利润
      myChart3: '',
      //echarts图表度电盈利
      myChart4: '',
      OperationData: {}
    }
  },
  //定义swiper对象
  computed: {},
  //钩子函数
  mounted () {
    this.mychartinit()
    //获取运营动态大屏数据接口
    this.getyydtejym()
  },
  methods: {
    //图标初始化
    mychartinit () {
      // 初始化echarts实例
      this.myChart1 = echarts.init(document.getElementById('echartyyzbfdl'))
      this.myChart2 = echarts.init(document.getElementById('echartyyzbsrqk'))
      this.myChart3 = echarts.init(document.getElementById('echartyyzblrqk'))
      this.myChart4 = echarts.init(document.getElementById('echartyyzbddyl'))
      var _this = this
      // 4. 让图表跟随屏幕自动的去适应
      window.addEventListener('resize', function () {
        _this.myChart1.resize()
        _this.myChart2.resize()
        _this.myChart3.resize()
        _this.myChart4.resize()
      })
    },
    //获取运营动态大屏数据接口
    getyydtejym () {
      var _this = this
      this.axios
        .get('financial/getOperationNews')
        .then((response) => {
          //储能
          var line1 = []
          //分布式光伏
          var line2 = []
          //集中式光伏
          var line3 = []

          //月份
          var line4 = []

          var line5 = []
          line4 = response.data.mouthlist
          //发电量
          var length = response.data.fdllist.length
          if (length > 0) {
            line1 = response.data.fdllist.map((data) => data.cn)
            line2 = response.data.fdllist.map((data) => data.fbsgf)
            line3 = response.data.fdllist.map((data) => data.jzsgf)
            line5 = response.data.fdllist.map(data => data.sdyw)
          }
          _this.mychart1(_this.myChart1, line1, line2, line3, line4, line5)

          //收入
          length = response.data.srlist.length
          if (length > 0) {
            line1 = response.data.srlist.map((data) => data.cn)
            line2 = response.data.srlist.map((data) => data.fbsgf)
            line3 = response.data.srlist.map((data) => data.jzsgf)
            line5 = response.data.srlist.map(data => data.sdyw)
          }
          _this.mychart2(_this.myChart2, line1, line2, line3, line4, line5)

          //利润
          length = response.data.lrlist.length
          if (length > 0) {
            line1 = response.data.lrlist.map((data) => data.cn)
            line2 = response.data.lrlist.map((data) => data.fbsgf)
            line3 = response.data.lrlist.map((data) => data.jzsgf)
            line5 = response.data.lrlist.map(data => data.sdyw)
          }
          _this.mychart3(_this.myChart3, line1, line2, line3, line4, line5)
          //度电盈利
          length = response.data.ddyllist.length
          if (length > 0) {
            line1 = response.data.ddyllist.map((data) => data.cn)
            line2 = response.data.ddyllist.map((data) => data.fbsgf)
            line3 = response.data.ddyllist.map((data) => data.jzsgf)
            line5 = response.data.ddyllist.map(data => data.sdyw)
          }
          _this.mychart4(_this.myChart4, line1, line2, line3, line4, line5)
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => (this.loading = false))
      this.axios
        .get('financial/getMobileOperationNews')
        .then(res => {
          this.OperationData = res.data
        })
    },
    mychart1 (myChart, line1, line2, line3, line4, line5) {
      // 2. 指定配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            textStyle: {
              color: '#fff'
            }
          }
        },
        title: {
          text: '发电量(万KWh/百万KWh)',
          textStyle: {
            color: 'white',
            fontSize: 13
          },
          left: '2%',
          top: '8%'
        },
        grid: {
          top: '25%',
          left: '3%',
          right: '3%',
          bottom: '10%',
          containLabel: true,
          textStyle: {
            color: '#fff'
          }
        },
        calculable: true,
        legend: {
          bottom: 0,
          textStyle: {
            color: '#90979c'
          },
          data: ['储能', '分布式光伏', '集中光伏', '售电业务']
        },

        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(204,187,225,0.5)'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            data: line4
          }
        ],

        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(204,187,225,0.5)'
              }
            }
          }
        ],
        series: [
          {
            name: '储能',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#6f7de3'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
              // data: [{
              //     type: 'max',
              //     name: '最大值',

              // }, {
              //     type: 'min',
              //     name: '最小值'
              // }]
            },
            data: line1,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(108,80,243,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(108,80,243,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(108,80,243, 0.9)',
                shadowBlur: 20
              }
            }
          },
          {
            name: '分布式光伏',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#c257F6'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
              // data: [{
              //     type: 'max',
              //     name: '最大值',

              // }, {
              //     type: 'min',
              //     name: '最小值'
              // }]
            },
            data: line2,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(108,80,243,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(108,80,243,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(108,80,243, 0.9)',
                shadowBlur: 20
              }
            }
          },
          {
            name: '集中光伏',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#1df3d6'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
              // data: [{
              //     type: 'max',
              //     name: '最大值',

              // }, {
              //     type: 'min',
              //     name: '最小值'
              // }]
            },
            data: line3,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0,202,149,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,202,149,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0,202,149, 0.9)',
                shadowBlur: 20
              }
            }
          },
          // {
          //   name: '售电业务',
          //   type: 'line',
          //   symbolSize: 10,
          //   symbol: 'circle',
          //   itemStyle: {
          //     color: '#f3be1d'
          //   },
          //   markPoint: {
          //     label: {
          //       normal: {
          //         textStyle: {
          //           color: '#fff'
          //         }
          //       }
          //     }
          //     // data: [{
          //     //     type: 'max',
          //     //     name: '最大值',
          //
          //     // }, {
          //     //     type: 'min',
          //     //     name: '最小值'
          //     // }]
          //   },
          //   data: line5,
          //   areaStyle: {
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(
          //         0,
          //         0,
          //         0,
          //         1,
          //         [
          //           {
          //             offset: 0,
          //             color: 'rgba(205,161,2,0.3)'
          //           },
          //           {
          //             offset: 1,
          //             color: 'rgba(0,202,149,0)'
          //           }
          //         ],
          //         false
          //       ),
          //       shadowColor: 'rgba(202,182,0,0.9)',
          //       shadowBlur: 20
          //     }
          //   }
          // },
        ]
      }
      myChart.setOption(option)
    },
    mychart2 (myChart, line1, line2, line3, line4, line5) {
      // 2. 指定配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            textStyle: {
              color: '#fff'
            }
          }
        },
        title: {
          text: '收入情况（万元）',
          textStyle: {
            color: 'white',
            fontSize: 13
          },
          left: '2%',
          top: '8%'
        },
        grid: {
          top: '25%',
          left: '3%',
          right: '3%',
          bottom: '10%',
          containLabel: true,
          textStyle: {
            color: '#fff'
          }
        },
        calculable: true,
        legend: {
          bottom: 0,
          textStyle: {
            color: '#90979c'
          },
          data: ['储能', '分布式光伏', '集中光伏', '售电业务']
        },

        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(204,187,225,0.5)'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            data: line4
          }
        ],

        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(204,187,225,0.5)'
              }
            }
          }
        ],
        series: [
          {
            name: '储能',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#6f7de3'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
            },
            data: line1,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(108,80,243,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(108,80,243,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(108,80,243, 0.9)',
                shadowBlur: 20
              }
            }
          },
          {
            name: '分布式光伏',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#c257F6'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
            },
            data: line2,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(108,80,243,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(108,80,243,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(108,80,243, 0.9)',
                shadowBlur: 20
              }
            }
          },
          {
            name: '集中光伏',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#1df3d6'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
              // data: [{
              //     type: 'max',
              //     name: '最大值',

              // }, {
              //     type: 'min',
              //     name: '最小值'
              // }]
            },
            data: line3,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0,202,149,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,202,149,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0,202,149, 0.9)',
                shadowBlur: 20
              }
            }
          },
          // {
          //   name: '售电业务',
          //   type: 'line',
          //   symbolSize: 10,
          //   symbol: 'circle',
          //   itemStyle: {
          //     color: '#f3be1d'
          //   },
          //   markPoint: {
          //     label: {
          //       normal: {
          //         textStyle: {
          //           color: '#fff'
          //         }
          //       }
          //     }
          //     // data: [{
          //     //     type: 'max',
          //     //     name: '最大值',
          //
          //     // }, {
          //     //     type: 'min',
          //     //     name: '最小值'
          //     // }]
          //   },
          //   data: line5,
          //   areaStyle: {
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(
          //         0,
          //         0,
          //         0,
          //         1,
          //         [
          //           {
          //             offset: 0,
          //             color: 'rgba(205,161,2,0.3)'
          //           },
          //           {
          //             offset: 1,
          //             color: 'rgba(0,202,149,0)'
          //           }
          //         ],
          //         false
          //       ),
          //       shadowColor: 'rgba(202,182,0,0.9)',
          //       shadowBlur: 20
          //     }
          //   }
          // },
        ]
      }
      // 3. 把配置项给实例对象
      myChart.setOption(option)
    },
    mychart3 (myChart, line1, line2, line3, line4, line5) {
      // 2. 指定配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            textStyle: {
              color: '#fff'
            }
          }
        },
        title: {
          text: '利润情况（万元）',
          textStyle: {
            color: 'white',
            fontSize: 13
          },
          left: '2%',
          top: '8%'
        },
        grid: {
          top: '25%',
          left: '3%',
          right: '3%',
          bottom: '10%',
          containLabel: true,
          textStyle: {
            color: '#fff'
          }
        },
        calculable: true,
        legend: {
          bottom: 0,
          textStyle: {
            color: '#90979c'
          },
          data: ['储能', '分布式光伏', '集中光伏', '售电业务']
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(204,187,225,0.5)'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            data: line4
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(204,187,225,0.5)'
              }
            }
          }
        ],
        series: [
          {
            name: '储能',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#6f7de3'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
            },
            data: line1,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(108,80,243,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(108,80,243,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(108,80,243, 0.9)',
                shadowBlur: 20
              }
            }
          },
          {
            name: '分布式光伏',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#c257F6'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
            },
            data: line2,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(108,80,243,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(108,80,243,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(108,80,243, 0.9)',
                shadowBlur: 20
              }
            }
          },
          {
            name: '集中光伏',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#1df3d6'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
            },
            data: line3,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0,202,149,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,202,149,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0,202,149, 0.9)',
                shadowBlur: 20
              }
            }
          },
          // {
          //   name: '售电业务',
          //   type: 'line',
          //   symbolSize: 10,
          //   symbol: 'circle',
          //   itemStyle: {
          //     color: '#f3be1d'
          //   },
          //   markPoint: {
          //     label: {
          //       normal: {
          //         textStyle: {
          //           color: '#fff'
          //         }
          //       }
          //     }
          //     // data: [{
          //     //     type: 'max',
          //     //     name: '最大值',
          //
          //     // }, {
          //     //     type: 'min',
          //     //     name: '最小值'
          //     // }]
          //   },
          //   data: line5,
          //   areaStyle: {
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(
          //         0,
          //         0,
          //         0,
          //         1,
          //         [
          //           {
          //             offset: 0,
          //             color: 'rgba(205,161,2,0.3)'
          //           },
          //           {
          //             offset: 1,
          //             color: 'rgba(0,202,149,0)'
          //           }
          //         ],
          //         false
          //       ),
          //       shadowColor: 'rgba(202,182,0,0.9)',
          //       shadowBlur: 20
          //     }
          //   }
          // },
        ]
      }
      // 3. 把配置项给实例对象
      myChart.setOption(option)
    },
    mychart4 (myChart, line1, line2, line3, line4, line5) {
      // 2. 指定配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            textStyle: {
              color: '#fff'
            }
          }
        },
        title: {
          text: '度电盈利（分/度）',
          textStyle: {
            color: 'white',
            fontSize: 13
          },
          left: '2%',
          top: '8%'
        },
        grid: {
          top: '25%',
          left: '3%',
          right: '3%',
          bottom: '10%',
          containLabel: true,
          textStyle: {
            color: '#fff'
          }
        },
        calculable: true,
        legend: {
          bottom: 0,
          textStyle: {
            color: '#90979c'
          },
          data: ['储能', '分布式光伏', '集中光伏', '售电业务']
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(204,187,225,0.5)'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            data: line4
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(204,187,225,0.5)'
              }
            }
          }
        ],
        series: [
          {
            name: '储能',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#6f7de3'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
            },
            data: line1,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(108,80,243,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(108,80,243,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(108,80,243, 0.9)',
                shadowBlur: 20
              }
            }
          },
          {
            name: '分布式光伏',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#c257F6'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
              // data: [{
              //     type: 'max',
              //     name: '最大值',

              // }, {
              //     type: 'min',
              //     name: '最小值'
              // }]
            },
            data: line2,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(108,80,243,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(108,80,243,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(108,80,243, 0.9)',
                shadowBlur: 20
              }
            }
          },
          {
            name: '集中光伏',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#1df3d6'
            },
            markPoint: {
              label: {
                normal: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              }
              // data: [{
              //     type: 'max',
              //     name: '最大值',

              // }, {
              //     type: 'min',
              //     name: '最小值'
              // }]
            },
            data: line3,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0,202,149,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,202,149,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(0,202,149, 0.9)',
                shadowBlur: 20
              }
            }
          },
          // {
          //   name: '售电业务',
          //   type: 'line',
          //   symbolSize: 10,
          //   symbol: 'circle',
          //   itemStyle: {
          //     color: '#f3be1d'
          //   },
          //   markPoint: {
          //     label: {
          //       normal: {
          //         textStyle: {
          //           color: '#fff'
          //         }
          //       }
          //     }
          //     // data: [{
          //     //     type: 'max',
          //     //     name: '最大值',
          //
          //     // }, {
          //     //     type: 'min',
          //     //     name: '最小值'
          //     // }]
          //   },
          //   data: line5,
          //   areaStyle: {
          //     normal: {
          //       color: new echarts.graphic.LinearGradient(
          //         0,
          //         0,
          //         0,
          //         1,
          //         [
          //           {
          //             offset: 0,
          //             color: 'rgba(205,161,2,0.3)'
          //           },
          //           {
          //             offset: 1,
          //             color: 'rgba(0,202,149,0)'
          //           }
          //         ],
          //         false
          //       ),
          //       shadowColor: 'rgba(202,182,0,0.9)',
          //       shadowBlur: 20
          //     }
          //   }
          // },
        ]
      }
      myChart.setOption(option)
    },
  },
}
</script>


<style lang="less" scoped>
ul, li {
  list-style: none;
}

.newChartInfo {
  background-size: 100% 100% !important;
  display: flex;
  align-items: center;
  padding-top: 0 !important;
  height: 3.5rem !important;
}

.profitList {
  font-size: 15px;
  color: #fff;
  margin-left: 20px;

  .profitList-title {
    color: skyblue;
    font-size: 18px;
  }

  .profitList-item {
    ul {
      li {
        margin-left: 50px;

        span {
          margin-left: 20px;
          color: rgb(255, 226, 10);
        }
      }
    }
  }
}
</style>
