<template>
  <!-- 当日盈利 -->
  <div class="mobilepanel">
    <div class="head">
      <div class="lefttitle">当日盈利</div>
      <!--      <div class="leftimg"></div>-->
      <slot></slot>
    </div>
    <div class="chartinfo newChartInfo">
      <div class="profitList">
        <div class="profitList-item">
          <div class="profitList-title">
            当日动态
          </div>
          <ul>
            <li>电站总数: <span>{{ dayData.dzzs }}座</span></li>
            <li>盈利电站: <span>{{ dayData.yldz }}座</span></li>
            <li>当日盈利: <span>{{ dayData.ryl }}万元</span></li>
            <li>月累计盈利: <span>{{ dayData.ydljyl }}万元</span></li>
            <li>月度完成率: <span>{{ dayData.ydwcl }}%</span></li>
            <li>年累计盈利: <span>{{ dayData.ndljyl }}万元</span></li>
            <li>年计划完成进度: <span>{{ dayData.ndwcl }}%</span></li>
          </ul>
        </div>
        <div class="profitList-item">
          <div class="profitList-title">
            光伏电站
          </div>
          <ul>
            <li>当日发电: <span>{{ PowerStationData.drfd }}万度</span></li>
            <li>月累计发电: <span>{{ PowerStationData.yljfd }}万度</span></li>
            <li>月度完成率: <span>{{ PowerStationData.ydwcl }}%</span></li>
            <li>年累计电量: <span>{{ PowerStationData.nljfdl }}万度</span></li>
            <li>年度计划完成进度: <span>{{ PowerStationData.njhwcjd }}%</span></li>
          </ul>
        </div>
        <div class="profitList-item">
          <div class="profitList-title">
            储能电站
          </div>
          <ul>
            <li>当日放电: <span>{{ EnergyData.drfd }}万度</span></li>
            <li>日充放效率: <span>{{ EnergyData.rcfxl }}%</span></li>
            <li>月累计发电量: <span>{{ EnergyData.yljfdl }}万度</span></li>
            <li>月度完成率: <span>{{ EnergyData.ydwcl }}%</span></li>
            <li>月充放效率: <span>{{ EnergyData.ycfxl }}%</span></li>
            <li>年累计发电量: <span>{{ EnergyData.nljfdl }}万度</span></li>
            <li>年度计划完成进度: <span>{{ EnergyData.njhwcjd }}%</span></li>
            <li>年充放效率: <span>{{ EnergyData.ncfxl }}%</span></li>
          </ul>
        </div>
      </div>
      <!--      <div class="charthead">-->
      <!--        <div class="charttitle">-->
      <!--          电站总数：&nbsp;<span-->
      <!--            style="-->
      <!--              color: yellow;-->
      <!--              font-size: 0.4rem;-->
      <!--              font-family: electronicFont;-->
      <!--            "-->
      <!--            v-text="dzzs"-->
      <!--          ></span-->
      <!--          >&nbsp;&nbsp;座-->
      <!--        </div>-->
      <!--        <div class="charttitle">-->
      <!--          盈利电站：&nbsp;<span-->
      <!--            style="-->
      <!--              color: yellow;-->
      <!--              font-size: 0.4rem;-->
      <!--              font-family: electronicFont;-->
      <!--            "-->
      <!--            v-text="yldz"-->
      <!--          ></span-->
      <!--          >&nbsp;&nbsp;座-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <ul>-->
      <!--        <li class="itemlihead">-->
      <!--          <span class="dzmc">电站名称</span>-->
      <!--          <span class="dl">电量</span>-->
      <!--          <span class="sr">收入</span>-->
      <!--          <span class="cb">成本</span>-->
      <!--          <span class="yl">盈利</span>-->
      <!--        </li>-->
      <!--      </ul>-->

      <!--      <div class="tablebox">-->
      <!--        <vue-seamless-scroll-->
      <!--          :data="tablelist"-->
      <!--          :class-option="classOption"-->
      <!--          class="seamless-warp"-->
      <!--          style="height: 100%; width: 98%; margin-left: 2%; overflow: hidden"-->
      <!--        >-->
      <!--          <ul class="item">-->
      <!--            <li v-for="(item, index) in tablelist" :key="index" class="itemli">-->
      <!--              <span-->
      <!--                :style="activation(item.bs)"-->
      <!--                class="dzmc"-->
      <!--                v-text="item.xmmc"-->
      <!--              ></span>-->
      <!--              <span-->
      <!--                :style="activation(item.bs)"-->
      <!--                class="dl"-->
      <!--                v-text="item.dl"-->
      <!--              ></span>-->
      <!--              <span-->
      <!--                :style="activation(item.bs)"-->
      <!--                class="sr"-->
      <!--                v-text="item.sr"-->
      <!--              ></span>-->
      <!--              <span-->
      <!--                :style="activation(item.bs)"-->
      <!--                class="cb"-->
      <!--                v-text="item.cb"-->
      <!--              ></span>-->
      <!--              <span-->
      <!--                :style="activation(item.bs)"-->
      <!--                class="yl"-->
      <!--                v-text="item.yl"-->
      <!--              ></span>-->
      <!--            </li>-->
      <!--          </ul>-->
      <!--        </vue-seamless-scroll>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  components: {
    //在此处注册
  },
  data () {
    return {
      //电站总数
      dzzs: '',
      //盈利电站
      yldz: '',
      //当日盈利轮播数据
      tablelist: [],
      dayData: {},
      PowerStationData: {},
      EnergyData: {}
    }
  },
  created () {
    //获取当日盈利接口数据
    this.getdryl()
  },
  //钩子函数
  mounted () {
  },
  computed: {
    //当日盈利根据标识设置颜色
    activation () {
      return (icontent) => {
        // 使用JavaScript闭包，进行传值操作
        if (icontent === '0') {
          return { color: 'white' }
        } else if (icontent === '1') {
          return { color: 'yellow' }
        } else if (icontent === '2') {
          return { color: 'red' }
        }
      }
    },
    classOption () {
      return {
        //循环速度
        step: 0.3,
      }
    },
  },
  methods: {
    //获取当日盈利数据接口
    getdryl () {
      // var _this = this
      // this.axios
      //   .get('financial/getProfitOfTheDay')
      //   .then((response) => {
      //     _this.tablelist = response.data.tablelist
      //     _this.dzzs = response.data.dzzs
      //     _this.yldz = response.data.yldz
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     this.errored = true
      //   })
      //   .finally(() => (this.loading = false))
      this.axios
        .get('financial/getNewsOfTheDay ')
        .then(res => {
          this.dayData = res.data
        })

      this.axios
        .get('financial/getPhotovoltaicPowerStation')
        .then(res => {
          this.PowerStationData = res.data[0]
        })

      this.axios
        .get('financial/getEnergyStoragePowerStation')
        .then(res => {
          console.log(res)
          this.EnergyData = res.data[0]
        })
    },
  },
}
</script>

<style lang="less" scoped>
ul, li {
  list-style: none;
}

.newChartInfo {
  background-size: 100% 100% !important;
  display: flex;
  align-items: center;
  padding-top: 0 !important;
  height: 14.5rem !important;
}

.profitList {
  font-size: 15px;
  color: #fff;
  margin-left: 20px;

  .profitList-title {
    color: skyblue;
    font-size: 18px;
  }

  .profitList-item {
    ul {
      li {
        margin-left: 50px;

        span {
          margin-left: 20px;
          color: rgb(255, 226, 10);
        }
      }
    }
  }
}
</style>
