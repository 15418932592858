<template>
  <div id="mobilemainhtml">
    <!-- 海螺大标题 -->
    <div class="mobiletop">
      <div class="toptitle">
        <!-- 渐变文字 -->
        <span class="topfontstyle2 gradient-text-one"
        >海螺新能源集中财务管理系统</span
        >
      </div>
    </div>
    <!-- 地图头部模块 -->
    <!--    <div class="mkcontain" style="height: 5rem">-->
    <!--    </div>-->
    <!-- 当日盈利模块 -->
    <template v-if="page === 1">
      <div class="mkcontain newMkcontain">
        <drylmoblie>
          <div class="right-icon" @click="page = 2">
            <i class="el-icon-arrow-right"></i>
          </div>
        </drylmoblie>
      </div>
      <div class="day">
        <day-profit-table/>
      </div>
      <!-- 运营告警模块 -->
      <div class="mkcontain" style="margin-top: 20px">
        <yygjmobile/>
      </div>
    </template>

    <template v-if="page === 2">
      <div class="bigmkcontain">
        <cwgkmobile>
          <div class="right-icon" @click="page = 1" style="left: 15px" slot="left">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="right-icon" @click="page = 3" slot="right">
            <i class="el-icon-arrow-right"></i>
          </div>
        </cwgkmobile>
      </div>
      <div class="mkcontain">
        <div class="head" style="margin-bottom: 10px">
          <div class="lefttitle">投资概况</div>
        </div>
        <!-- 累计投资模块 -->
        <div class="mkcontain1">
          <moblietopleft/>
        </div>
        <!-- 投运项目模块 -->
        <div class="mkcontain1">
          <moblietopright/>
        </div>
      </div>
      <!-- 财务概况模块 -->
    </template>

    <template v-if="page === 3">
      <!-- 现金结算模块 -->
      <div class="mkcontain">
        <xjjsmobile>
          <div class="right-icon" @click="page = 2" style="left: 15px" slot="left">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="right-icon" @click="page = 4" slot="right">
            <i class="el-icon-arrow-right"></i>
          </div>
        </xjjsmobile>

      </div>
      <!-- 运营动态模块 -->
      <div class="bigmkcontain" style="margin-top: 4rem">
        <yydtmobile/>
      </div>
    </template>

    <template v-if="page === 4">
      <!-- 综合排名模块 -->
      <div class="mkcontain">
        <zhpmmobile>
          <div class="right-icon" @click="page = 3" style="left: 15px">
            <i class="el-icon-arrow-left"></i>
          </div>
        </zhpmmobile>
      </div>
      <!-- 建设概况模块 -->
      <div class="bigmkcontain" style="margin-top:.5rem;">
        <jsgkmobile/>
      </div>
    </template>

  </div>
</template>

<script>
import drylmoblie from '../components/moblie/drylmoblie.vue'
import moblietopleft from '../components/moblie/mobiletopleftcontent.vue'
import moblietopright from '../components/moblie/mobiletoprightcontent.vue'
import yygjmobile from '../components/moblie/yygjmobile.vue'
import cwgkmobile from '../components/moblie/cwgkmobile.vue'
import xjjsmobile from '../components/moblie/xjjsmobile.vue'
import yydtmobile from '../components/moblie/yydtmobile.vue'
import zhpmmobile from '../components/moblie/zhpmmobile.vue'
import jsgkmobile from '../components/moblie/jsgkmobile.vue'
import DayProfitTable from '@/components/dayProfitTable'

export default {
  props: {
    page: {
      type: Number,
      default: 1
    }
  },
  components: {
    DayProfitTable,
    //在此处注册
    drylmoblie,
    moblietopleft,
    moblietopright,
    yygjmobile,
    cwgkmobile,
    xjjsmobile,
    yydtmobile,
    zhpmmobile,
    jsgkmobile,
  },
  data () {
    return {}
  },
  created () {
  },
  mounted () {
  },
  methods: {

  },
}
</script>

<style>
.newMkcontain {
  height: auto !important;
}
</style>
