<template>
  <div>
    <div class="mobiletopcontent">
      <div class="topbox">
        <div class="topboxtop">
          {{ tzgllist[0].tyxm }}&nbsp;&nbsp;<span style="font-size: 0.3rem"
        >个</span
        >
        </div>
        <div class="topboxbottom">投运项目</div>
      </div>
      <div class="topbox">
        <div class="topboxtop">
          {{ tzgllist[0].zjxm }}&nbsp;&nbsp;<span style="font-size: 0.3rem"
        >个</span
        >
        </div>
        <div class="topboxbottom">在建项目</div>
      </div>
      <div class="topbox">
        <div class="topboxtop">
          {{ tzgllist[0].kfxm }}&nbsp;&nbsp;<span style="font-size: 0.3rem"
        >个</span
        >
        </div>
        <div class="topboxbottom">开发项目</div>
      </div>
    </div>
    <!--    <div class="mobilepanel" style="    margin-top: 50px;">-->
    <!--      <div class="chartinfo newChartInfo">-->
    <!--        <div class="profitList">-->
    <!--          <div class="profitList-item">-->
    <!--            <div class="profitList-title">-->
    <!--              财务状况上方-->
    <!--            </div>-->
    <!--            <ul>-->
    <!--              <li>光伏电站: <span>{{ FinancialData.gfdz }}座</span></li>-->
    <!--              <li>储能电站: <span>{{ FinancialData.cndz }}座</span></li>-->
    <!--              <li>投资总额: <span>{{ FinancialData.tzze }}万元</span></li>-->
    <!--              <li>公司总资产: <span>{{ FinancialData.gszzc }}万元</span></li>-->
    <!--              <li>资产负债率: <span>{{ FinancialData.zcfzl }}%</span></li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--          <div class="profitList-item">-->
    <!--            <div class="profitList-title">-->
    <!--              运营动态上方-->
    <!--            </div>-->
    <!--            <ul>-->
    <!--              <li>光伏电量: <span>{{ OperationData.gfdl }}万度</span></li>-->
    <!--              <li>储能电量: <span>{{ OperationData.cndl }}万度</span></li>-->
    <!--              <li>售电电量: <span>{{ OperationData.sddl }}万度</span></li>-->
    <!--              <li>实际收入: <span>{{ OperationData.sxsr }}万元</span></li>-->
    <!--              <li>盈利: <span>{{ OperationData.yl }}万元</span></li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--          <div class="profitList-item">-->
    <!--            <div class="profitList-title">-->
    <!--              现金结算上方-->
    <!--            </div>-->
    <!--            <ul>-->
    <!--              <li>累计回款: <span>{{ CashSettleData.ljhk }}万元</span></li>-->
    <!--              <li>应收账款余额: <span>{{ CashSettleData.yszkye }}万元</span></li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        &lt;!&ndash;      <div class="charthead">&ndash;&gt;-->
    <!--        &lt;!&ndash;        <div class="charttitle">&ndash;&gt;-->
    <!--        &lt;!&ndash;          电站总数：&nbsp;<span&ndash;&gt;-->
    <!--        &lt;!&ndash;            style="&ndash;&gt;-->
    <!--        &lt;!&ndash;              color: yellow;&ndash;&gt;-->
    <!--        &lt;!&ndash;              font-size: 0.4rem;&ndash;&gt;-->
    <!--        &lt;!&ndash;              font-family: electronicFont;&ndash;&gt;-->
    <!--        &lt;!&ndash;            "&ndash;&gt;-->
    <!--        &lt;!&ndash;            v-text="dzzs"&ndash;&gt;-->
    <!--        &lt;!&ndash;          ></span&ndash;&gt;-->
    <!--        &lt;!&ndash;          >&nbsp;&nbsp;座&ndash;&gt;-->
    <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <div class="charttitle">&ndash;&gt;-->
    <!--        &lt;!&ndash;          盈利电站：&nbsp;<span&ndash;&gt;-->
    <!--        &lt;!&ndash;            style="&ndash;&gt;-->
    <!--        &lt;!&ndash;              color: yellow;&ndash;&gt;-->
    <!--        &lt;!&ndash;              font-size: 0.4rem;&ndash;&gt;-->
    <!--        &lt;!&ndash;              font-family: electronicFont;&ndash;&gt;-->
    <!--        &lt;!&ndash;            "&ndash;&gt;-->
    <!--        &lt;!&ndash;            v-text="yldz"&ndash;&gt;-->
    <!--        &lt;!&ndash;          ></span&ndash;&gt;-->
    <!--        &lt;!&ndash;          >&nbsp;&nbsp;座&ndash;&gt;-->
    <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;      <ul>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <li class="itemlihead">&ndash;&gt;-->
    <!--        &lt;!&ndash;          <span class="dzmc">电站名称</span>&ndash;&gt;-->
    <!--        &lt;!&ndash;          <span class="dl">电量</span>&ndash;&gt;-->
    <!--        &lt;!&ndash;          <span class="sr">收入</span>&ndash;&gt;-->
    <!--        &lt;!&ndash;          <span class="cb">成本</span>&ndash;&gt;-->
    <!--        &lt;!&ndash;          <span class="yl">盈利</span>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </li>&ndash;&gt;-->
    <!--        &lt;!&ndash;      </ul>&ndash;&gt;-->

    <!--        &lt;!&ndash;      <div class="tablebox">&ndash;&gt;-->
    <!--        &lt;!&ndash;        <vue-seamless-scroll&ndash;&gt;-->
    <!--        &lt;!&ndash;          :data="tablelist"&ndash;&gt;-->
    <!--        &lt;!&ndash;          :class-option="classOption"&ndash;&gt;-->
    <!--        &lt;!&ndash;          class="seamless-warp"&ndash;&gt;-->
    <!--        &lt;!&ndash;          style="height: 100%; width: 98%; margin-left: 2%; overflow: hidden"&ndash;&gt;-->
    <!--        &lt;!&ndash;        >&ndash;&gt;-->
    <!--        &lt;!&ndash;          <ul class="item">&ndash;&gt;-->
    <!--        &lt;!&ndash;            <li v-for="(item, index) in tablelist" :key="index" class="itemli">&ndash;&gt;-->
    <!--        &lt;!&ndash;              <span&ndash;&gt;-->
    <!--        &lt;!&ndash;                :style="activation(item.bs)"&ndash;&gt;-->
    <!--        &lt;!&ndash;                class="dzmc"&ndash;&gt;-->
    <!--        &lt;!&ndash;                v-text="item.xmmc"&ndash;&gt;-->
    <!--        &lt;!&ndash;              ></span>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <span&ndash;&gt;-->
    <!--        &lt;!&ndash;                :style="activation(item.bs)"&ndash;&gt;-->
    <!--        &lt;!&ndash;                class="dl"&ndash;&gt;-->
    <!--        &lt;!&ndash;                v-text="item.dl"&ndash;&gt;-->
    <!--        &lt;!&ndash;              ></span>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <span&ndash;&gt;-->
    <!--        &lt;!&ndash;                :style="activation(item.bs)"&ndash;&gt;-->
    <!--        &lt;!&ndash;                class="sr"&ndash;&gt;-->
    <!--        &lt;!&ndash;                v-text="item.sr"&ndash;&gt;-->
    <!--        &lt;!&ndash;              ></span>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <span&ndash;&gt;-->
    <!--        &lt;!&ndash;                :style="activation(item.bs)"&ndash;&gt;-->
    <!--        &lt;!&ndash;                class="cb"&ndash;&gt;-->
    <!--        &lt;!&ndash;                v-text="item.cb"&ndash;&gt;-->
    <!--        &lt;!&ndash;              ></span>&ndash;&gt;-->
    <!--        &lt;!&ndash;              <span&ndash;&gt;-->
    <!--        &lt;!&ndash;                :style="activation(item.bs)"&ndash;&gt;-->
    <!--        &lt;!&ndash;                class="yl"&ndash;&gt;-->
    <!--        &lt;!&ndash;                v-text="item.yl"&ndash;&gt;-->
    <!--        &lt;!&ndash;              ></span>&ndash;&gt;-->
    <!--        &lt;!&ndash;            </li>&ndash;&gt;-->
    <!--        &lt;!&ndash;          </ul>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </vue-seamless-scroll>&ndash;&gt;-->
    <!--        &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

</template>

<script>
export default {
  data () {
    return {
      tzgllist: [
        {
          tyxm: '',
          zjxm: '',
          kfxm: '',
        },
      ],
      FinancialData: {},
      OperationData: {},
      CashSettleData: {}
    }
  },
  //钩子函数
  mounted () {
    this.getdtzyxx2()
  },
  methods: {
    //地图中央信息接口
    getdtzyxx2 () {
      var _this = this
      this.axios
        .get('financial/getMapCenterInformation')
        .then((response) => {
          _this.tzgllist = response.data.tzgllist
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="less" scoped>
ul, li {
  list-style: none;
}

.newChartInfo {
  background-size: 100% 100% !important;
  display: flex;
  align-items: center;
  padding-top: 0 !important;
  height: 9.5rem !important;
}

.profitList {
  font-size: 15px;
  color: #fff;
  margin-left: 20px;

  .profitList-title {
    color: skyblue;
    font-size: 18px;
  }

  .profitList-item {
    ul {
      li {
        margin-left: 50px;

        span {
          margin-left: 20px;
          color: rgb(255, 226, 10);
        }
      }
    }
  }
}
</style>
