import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tzqsisshow: false,
    xmztxqishow: false,
    xmztxqsjishow: false,
    xjjsejishow: false,
    cwgkejishow: false,
    yydtejishow: false,
    yygjejishow: false,
    zhpmejishow: false,
    zbtjsjishow: false,
    xmdbsjishow: false
  },
  mutations: {
    update (state, [key, value]) {
      state[key] = value
    }
  },
  actions: {
  },
  modules: {}
})
