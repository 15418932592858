<template>
  <!-- 运营告警动态 -->
  <div class="mobilepanel">
    <div class="head">
      <div class="lefttitle">经营告警</div>
      <!--      <div class="leftimg"></div>-->
    </div>
    <div class="chartinfo">
      <div class="charthead">
        <div class="charttitle">
          当日告警：&nbsp;<span
          style="
              color: yellow;
              font-size: 0.4rem;
              font-family: electronicFont;
            "
        >{{ dtgj }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
          条
        </div>
        <div class="charttitle">
          累计告警：&nbsp;<span
          style="
              color: yellow;
              font-size: 0.4rem;
              font-family: electronicFont;
            "
        >{{ ljgj }}</span
        >&nbsp;&nbsp;&nbsp;&nbsp;条
        </div>
      </div>
      <ul>
        <li class="itemlihead">
          <span class="fssj">发生时间</span>
          <span class="dzmc1">电站名称</span>
          <span class="gjlx">预警信息</span>
          <span class="zt">状态</span>
        </li>
      </ul>

      <div class="tablebox">
        <vue-seamless-scroll
          :data="tablelist"
          :class-option="classOption"
          class="seamless-warp"
          style="height: 100%; width: 98%; margin-left: 2%; overflow: hidden"
        >
          <ul class="item">
            <li v-for="(item, index) in tablelist" :key="index" class="itemli">
              <div class="fssj" v-text="item.fssj"></div>
              <div class="dzmc1" v-text="item.dzmc"></div>
              <div class="gjlx" v-text="item.tznr"></div>
              <div
                :style="activation(item.zt)"
                class="zt"
                v-text="item.zt"
              ></div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewDate } from '@/utils'

export default {
  data () {
    return {
      //当天告警
      dtgj: '',
      //累计告警
      ljgj: '',
      //tablelist
      tablelist: [
        {
          fssj: '',
          dzmc: '',
          gjlx: '',
          zt: '',
        },
      ],
    }
  },
  components: {},
  //钩子函数
  mounted () {
    //获取运营告警大屏数据接口
    this.getyygj()
  },
  computed: {
    //运营告警根据标识设置颜色
    activation () {
      return (icontent) => {
        // 使用JavaScript闭包，进行传值操作
        if (icontent.indexOf('未确认') != -1) {
          return { color: '#1bf3ee' }
        } else {
          return { color: '#f1d712' }
        }
      }
    },
    classOption () {
      return {
        //循环速度
        step: 0.3,
      }
    },
  },
  methods: {
    //获取运营告警大屏数据接口
    getyygj () {
      var _this = this
      this.axios
        .get('financial/getWarningOfTheDay', {
          params: {
            // 大屏轮播20条数据
            pageSize: 20,
            page: 1,
            kssj: getNewDate('before', 2),
            jssj: getNewDate('now'),
            zt: '',
            dp: 1
          },
        })
        .then((response) => {
          //获取当天告警
          _this.dtgj = response.data.dtgj
          //获取累计告警
          _this.ljgj = response.data.ljgj
          _this.tablelist = response.data.tablelist.records
          if (_this.tablelist.length != 0) {
            for (var i = 0; i < _this.tablelist.length; i++) {
              var a = _this.tablelist[i].fssj
              if (a != null) {
                _this.tablelist[i].fssj = a.slice(0, 10)
              }
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>
