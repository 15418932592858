export const regNumber = array => {
  const newArray = [];
  array.map(item => {
    newArray.push(Math.floor(+item));
  });
  return newArray;
};
export const getNewDate = (flag, many) => {
  const thirtyDays = [4, 6, 9, 11]; // 30天的月份
  const thirtyOneDays = [1, 3, 5, 7, 8, 10, 12]; // 31天的月份
  const currDate = new Date(); // 今天日期
  const year = currDate.getFullYear();
  let month = currDate.getMonth() + 1;
  let nowTime = `${year}-${month.toString().padStart(2, "0")}-${currDate
    .getDate()
    .toString()
    .padStart(2, "0")}`;
  let targetDateMilli = 0;
  let GMTDate = ""; // 中国标准时间
  let targetYear = ""; // 年
  let targetMonth = ""; // 月
  let targetDate = ""; // 日
  let dealTargetDays = ""; // 目标日期
  const isLeapYear = !!((year % 4 == 0 && year % 100 != 0) || year % 400 == 0); // 是否是闰年
  // console.log(isLeapYear, "isLeapYear");
  let countDays = 0; // 累计天数
  if (flag === "now") {
    return nowTime;
  }
  for (let i = 0; i < many; i++) {
    if (flag === "before") {
      month = month - 1 <= 0 ? 12 : month - 1;
    } else {
      month = month + 1 > 12 ? 1 : month + 1;
    }
    thirtyDays.includes(month)
      ? (countDays += 30)
      : thirtyOneDays.includes(month)
      ? (countDays += 31)
      : isLeapYear
        ? (countDays += 29)
        : (countDays += 28);
  }
  targetDateMilli = currDate.setDate(
    currDate.getDate() - (flag === "before" ? countDays : countDays * -1)
  );
  GMTDate = new Date(targetDateMilli);
  targetYear = GMTDate.getFullYear();
  targetMonth = GMTDate.getMonth() + 1;
  targetDate = GMTDate.getDate();
  targetMonth = targetMonth.toString().padStart(2, "0");
  targetDate = targetDate.toString().padStart(2, "0");
  dealTargetDays = `${targetYear}-${targetMonth}-${targetDate}`;
  return dealTargetDays;
};
