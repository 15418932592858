<template>
  <div class="zhpmejymcontent">
    <div class="zhpmejymtitle" style="
    font-size: 0.5rem;
    line-height: 1rem;
    color: #1afff8;
    margin-left: 0.15rem;
    font-weight: bold;
    text-align: center;">当日盈利</div>
    <!-- 图表 -->
    <el-table
      :data="tableData"
      :height="400"
      style="
        width: 100%;
        border: 1px solid #1afff8;
      "
      :cell-style="setCellColor"
    >
      <el-table-column prop="xmmc" label="电站名称"  align="center"   fixed>
      </el-table-column>
      <el-table-column
        prop="dl"
        label="日电量(度)"
        align="center"
        style="color: #00ffba!important;"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="cb"-->
      <!--        label="成本(元)"-->
      <!--        min-width="30%"-->
      <!--        align="center"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <el-table-column
        prop="sr"
        label="日收入(元)"

        align="center"
        style="color: #00ffba!important;"
      >
      </el-table-column>
      <el-table-column
        prop="lr"
        label="日利润(元)"

        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="byljdl"
        label="本月累计电量(度)"
        align="center"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="byysdl"-->
      <!--        label="本月预算电量(度)"-->
      <!--        min-width="30%"-->
      <!--        align="center"-->
      <!--      >-->
      <!--      </el-table-column> -->
      <el-table-column
        prop="ylr"
        label="本月累计利润(元)"
        align="center"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="byljdl"-->
      <!--        label="本月累计电量(度)"-->
      <!--        min-width="30%"-->
      <!--        align="center"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <el-table-column
        prop="dydlwcqk"
        label="月电量完成率"
        align="center"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="nddlwcqk"-->
      <!--        label="年度电量完成情况"-->
      <!--        min-width="30%"-->
      <!--        align="center"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop="ndsrwcqk"-->
      <!--        label="年度收入完成情况"-->
      <!--        min-width="30%"-->
      <!--        align="center"-->
      <!--      >-->
      <!--      </el-table-column>    -->
      <!--      <el-table-column-->
      <!--        prop="dysrwcqk"-->
      <!--        label="月收入完成率"-->
      <!--        min-width="30%"-->
      <!--        align="center"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <el-table-column
        prop="dylrwcqk"
        label="月利润完成率"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="ndlrwcqk"
        label="年利润完成率"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'dayProfitTable',
  data () {
    return {
      tableData: [],
      monthValue: ''
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    setCellColor ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      const arr = [0],
        arr1 = [6, 7, 8],
        arr2 = [4, 5]
      if (arr.includes(columnIndex)) {
        return 'color:#1afff8'
      }
      if (arr1.includes(columnIndex)) {
        return 'color:#8dff00;'
      }
      if (arr2.includes(columnIndex)) {
        return 'color:#ffea00;'
      }
    },
    getTableData () {
      this.axios.get('financial/getProfitOfTheDaySecond', {
        params: {
          time: this.monthValue
        }
      }).then(res => {
        this.tableData = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-table__fixed, .el-table__fixed-right {
    background: #3a5169;
    /* opacity: 1.3; */
  }
}
</style>
