<template>
  <!-- 现金结算 -->
  <div class="mobilepanel bar">
    <div class="head" style="margin-bottom: 10px">
      <slot name="left"></slot>
      <div class="lefttitle">现金结算</div>
      <!--      <div class="leftimg"></div>-->
      <slot name="right"></slot>
    </div>
    <div class="chartinfo newChartInfo">
      <div class="profitList">
        <div class="profitList-item">
          <ul>
            <li>累计回款: <span>{{ CashSettleData.ljhk }}万元</span></li>
            <li>应收账款余额: <span>{{ CashSettleData.yszkye }}万元</span></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 柱状图 -->
    <div class="chartinfo">
      <!-- <div class="charthead">
          <div class="charttitle">月出数</div>
          <div class="charttitle">资金流入</div>
          <div class="charttitle">资金流出</div>
        </div>
        <div class="charthead1">
          <div class="charttitle1">1119万元</div>
          <div class="charttitle1">3994万元</div>
          <div class="charttitle1">4375万元</div>
        </div> -->
      <div id="echartxjjs" style="height: 100%" class="chart"></div>
    </div>
  </div>
</template>

<script>
import { regNumber } from '@/utils'

let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/bar')
require('echarts/lib/chart/pie')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/legend')

export default {
  components: {},
  data () {
    return {
      CashSettleData: {}
    }
  },
  created () {
    //获取现金结算大屏接口数据
    this.getxjjsdp()
  },
  //钩子函数
  mounted () {
  },
  computed: {},
  methods: {
    //获取现金结算大屏展示数据接口
    getxjjsdp () {
      var _this = this
      this.axios
        .get('financial/getCashSettlement')
        .then((response) => {
          var line = []
          var line1 = []
          for (var i = 0; i < response.data.length; i++) {
            line.push(response.data[0].cnhk)
            line.push(response.data[0].fbgfhk)
            line.push(response.data[0].jzgfhk)
            line.push(response.data[0].sdhk)

            line1.push(response.data[0].cnys)
            line1.push(response.data[0].fbgfys)
            line1.push(response.data[0].jzgfys)
            line1.push(response.data[0].sdys)
          }
          _this.echartxjjs(line, line1)
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => (this.loading = false))

      this.axios
        .get('financial/getMobileCashSettlement')
        .then(res => {
          console.log(res)
          this.CashSettleData = res.data
        })
    },

    echartxjjs (line, line1) {
      // 初始化echarts实例
      let myChart = echarts.init(document.getElementById('echartxjjs'))
      // var xData = ['储能', '分布光伏', '集中光伏', '售电业务']
      var xData = ['储能', '分布光伏', '集中光伏']
      var attr = {
        name: '应收账款',
        unit: '万元',
        name1: '回款金额',
      }
      // 2. 指定配置项和数据
      var option = {
        title: {
          text: '回款金额：万元',
          textStyle: {
            color: '#07f5fd',
            fontSize: 13
          },
          subtext: '应收账款余额：万元',
          subtextStyle: {
            color: 'rgb(224, 203, 14)',
            fontWeight: 'bold',
            fontSize: 13
          },
          left: '3%',
          top: '0.2%'
        },
        tooltip: {
          trigger: 'item',
          formatter: function (param) {
            if (param.seriesIndex == 4) {
              var resultTooltip =
                '<div style=\'background:rgba(255, 255, 255, 0.6);border:1px solid rgba(255,255,255,.2);padding:5px;border-radius:3px;\'>' +
                '<div style=\'text-align:center;\'>' +
                param.name +
                '</div>' +
                '<div style=\'padding-top:5px;\'>' +
                '<span style=\'\'> ' +
                attr.name +
                ': </span>' +
                '<span style=\'\'>' +
                param.value +
                '</span><span>' +
                attr.unit +
                '</span>' +
                '</div>' +
                '</div>'
            } else if (param.seriesIndex == 1) {
              var resultTooltip =
                '<div style=\'background:rgba(255, 255, 255, 0.6);border:1px solid rgba(255,255,255,.2);padding:5px;border-radius:3px;\'>' +
                '<div style=\'text-align:center;\'>' +
                param.name +
                '</div>' +
                '<div style=\'padding-top:5px;\'>' +
                '<span style=\'\'> ' +
                attr.name1 +
                ': </span>' +
                '<span style=\'\'>' +
                param.value +
                '</span><span>' +
                attr.unit +
                '</span>' +
                '</div>' +
                '</div>'
            }

            return resultTooltip
          }
        },
        grid: {
          left: '5%%',
          top: '23%',
          right: '5%',
          bottom: '10%',
          containLabel: true
        },
        legend: {
          show: false,
          data: ['回款金额', '应收账款']
        },
        xAxis: [
          {
            data: xData,
            axisLabel: {
              textStyle: {
                color: 'white',
                fontSize: 14,
                fontWeight: '600'
              },
              margin: 30 //刻度标签与轴线之间的距离。
            },

            axisLine: {
              show: false //不显示x轴
            },
            axisTick: {
              show: false //不显示刻度
            },
            boundaryGap: true,
            splitLine: {
              show: false,
              width: 0.08,
              lineStyle: {
                type: 'solid',
                color: '#03202E'
              }
            }
          }
        ],
        yAxis: [
          {
            splitLine: {
              show: false,
              lineStyle: {
                color: '#eee',
                type: 'solid'
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: 'white'
              }
            }
          }
        ],
        series: [
          {
            //柱底圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [30, 6], //调整截面形状
            symbolOffset: ['-60%', '70%'],
            z: 6,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(89,211,255,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(23,237,194,1)'
                  }
                ])
              }
            },
            data: regNumber(line)
          },

          //柱体
          {
            name: '回款金额',
            type: 'bar',
            barWidth: [30],
            barGap: '20%',
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  type: 'linear',
                  global: false,
                  colorStops: [
                    {
                      //第一节下面
                      offset: 0,
                      color: 'rgba(0,255,245,0.5)'
                    },
                    {
                      offset: 1,
                      color: '#43bafe'
                    }
                  ]
                }
              }
            },

            data: regNumber(line),
            label: {
              show: true,
              position: 'top',
              // {c} 会自动的解析为 数据  data里面的数据
              formatter: '{c}',
              fontSize: 12,
              color: '#43bafe'
            }
          },

          //柱顶圆片
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [30, 6], //调整截面形状
            symbolOffset: ['-60%', '-70%'],
            z: 6,
            symbolPosition: 'end',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(89,211,255,1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(23,237,194,1)'
                    }
                  ],
                  false
                )
              }
            },
            data: regNumber(line)
          },
          ////////第二列
          {
            //柱底圆片
            name: '',
            type: 'pictorialBar',
            symbolSize: [30, 6], //调整截面形状
            symbolOffset: ['60%', '70%'],
            z: 6,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(248, 231, 105,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(238, 213, 25,1)'
                  }
                ])
              }
            },
            data: regNumber(line1)
          },

          //柱体
          {
            name: '应收账款',
            type: 'bar',
            barWidth: [30],
            barGap: '20%',
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  type: 'linear',
                  global: false,
                  colorStops: [
                    {
                      //第一节下面
                      offset: 0,
                      color: 'rgba(252, 235, 4,0.5)'
                    },
                    {
                      offset: 1,
                      color: 'rgb(224, 203, 14)'
                    }
                  ]
                }
              }
            },

            data: regNumber(line1),
            label: {
              show: true,
              position: 'top',
              // {c} 会自动的解析为 数据  data里面的数据
              formatter: '{c}',
              fontSize: 12,
              color: 'rgb(224, 203, 14)'
            }
          },

          //柱顶圆片
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [30, 6], //调整截面形状
            symbolOffset: ['60%', '-70%'],
            z: 6,
            symbolPosition: 'end',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(248, 231, 105,1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(238, 213, 25,1)'
                    }
                  ],
                  false
                )
              }
            },
            data: regNumber(line1)
          }
        ]
      }

      //防止越界，重绘canvas
      window.onresize = myChart.resize
      // 3. 把配置项给实例对象
      myChart.setOption(option)
      // 4. 让图表跟随屏幕自动的去适应
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
  },
}
</script>

<style lang="less" scoped>
ul, li {
  list-style: none;
}

.newChartInfo {
  background-size: 100% 100% !important;
  display: flex;
  align-items: center;
  padding-top: 0 !important;
  height: 3.5rem !important;
}

.profitList {
  font-size: 15px;
  color: #fff;
  margin-left: 20px;

  .profitList-title {
    color: skyblue;
    font-size: 18px;
  }

  .profitList-item {
    ul {
      li {
        margin-left: 50px;

        span {
          margin-left: 20px;
          color: rgb(255, 226, 10);
        }
      }
    }
  }
}
</style>
