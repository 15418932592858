<template>
  <!-- 建设概况 -->
  <div class="mobilepanel">
    <div class="head">
      <div class="lefttitle">指标汇总</div>
      <!--      <div class="leftimg"></div>-->
    </div>
    <!-- 添加的图片 -->
    <div class="twotb">
      <!-- 1并网容量-->
      <div class="tb">
        <div class="text" style="padding-top: 0.28rem">
          {{ bottomdatas[0].gfbwrl }}MWp
        </div>
        <div class="text">
          光伏并网容量
        </div>
        <img src="../../assets/image/z1.png" height="70" width="65"/>
      </div>
      <!-- 间隔 -->
      <div class="jg"></div>
      <div class="tb">
        <div class="text" style="padding-top: 0.28rem">
          {{ bottomdatas[0].cnbwrl }}MWp
        </div>
        <div class="text">
          储能并网电量
        </div>
        <!--            <div class="text">储能电站</div>-->
        <!--            <div class="text">并网容量（已接入）</div>-->
        <img src="../../assets/image/z1.png" height="70" width="65"/>
      </div>
      <div class="jg"></div>
    </div>
    <div class="twotb">
      <!-- 2社会效益 -->
      <div class="tb">
        <div class="text" style="padding-top: 0.28rem">
          {{ bottomdatas[0].xhxy }}万吨
        </div>
        <div class="text">二氧化碳</div>
        <div class="text">社会效益</div>
        <img src="../../assets/image/z2.png" height="70" width="65"/>
      </div>
      <div class="jg"></div>
      <!-- 3累计发/放电量 -->
      <div class="tb">
        <div class="text" style="padding-top: 0.5rem">
          {{ bottomdatas[0].ljffdl }}亿度
        </div>
        <div class="text">累计发/放电量</div>
        <img src="../../assets/image/z3.png" height="70" width="65"/>
      </div>
      <!-- 间隔 -->
    </div>
    <div class="twotb">
      <!--      &lt;!&ndash; 5系统平均效率 &ndash;&gt;-->
      <!--      <div class="tb">-->
      <!--        <div class="text" style="padding-top: 0.5rem">-->
      <!--          {{ bottomdatas[0].xtpjxl }}%-->
      <!--        </div>-->
      <!--        <div class="text">系统平均效率</div>-->
      <!--        <img src="../../assets/image/z5.png" height="70" width="65"/>-->
      <!--      </div>-->
      <!--      &lt;!&ndash; 间隔 &ndash;&gt;-->
      <!--      <div class="jg"></div>-->
      <!-- 6平均投资成本 -->
      <div class="tb">
        <div class="text" style="padding-top: 0.5rem">
          {{ bottomdatas[0].pjtzcb }}元/W
        </div>
        <div class="text">平均投资成本</div>
        <img src="../../assets/image/z6.png" height="70" width="65"/>
      </div>
      <div class="jg"></div>
      <div class="tb">
        <div class="text" style="padding-top: 0.28rem">
          {{ bottomdatas[0].npjfdyxxss }}小时
        </div>
        <div class="text">年平均发电</div>
        <div class="text">有效小时数</div>
        <img src="../../assets/image/z4.png" height="70" width="65"/>
      </div>
    </div>
    <div class="twotb">
      <!-- 7平均建设周期（天） -->
      <div class="tb">
        <div class="text" style="padding-top: 0.5rem">
          {{ bottomdatas[0].pjjszq }}天
        </div>
        <div class="text">平均建设周期</div>
        <img src="../../assets/image/z7.png" height="70" width="65"/>
      </div>
      <!-- 间隔 -->
      <!--      <div class="jg"></div>-->
      <!--      &lt;!&ndash; 8建设周期（已开工建设天数） &ndash;&gt;-->
      <!--      <div class="tb">-->
      <!--        <div class="text" style="padding-top: 0.28rem">-->
      <!--          {{ bottomdatas[0].jszq }}天-->
      <!--        </div>-->
      <!--        <div class="text">建设周期</div>-->
      <!--        <div class="text">（在建）</div>-->
      <!--        <img src="../../assets/image/z8.png" height="70" width="65"/>-->
      <!--      </div>-->
      <!--    </div>-->
      <!--    <div class="twotb">-->
      <!--      &lt;!&ndash; 9计划单位投资成本 &ndash;&gt;-->
      <!--      <div class="tb">-->
      <!--        <div class="text" style="padding-top: 0.28rem">-->
      <!--          {{ bottomdatas[0].jhdwtzcb }}-->
      <!--        </div>-->
      <!--        <div class="text">计划单位</div>-->
      <!--        <div class="text">投资成本</div>-->
      <!--        <img src="../../assets/image/z9.png" height="70" width="65"/>-->
      <!--      </div>-->
      <!--      &lt;!&ndash; 间隔 &ndash;&gt;-->
      <!--      <div class="jg"></div>-->
      <!--      &lt;!&ndash; 空占位 &ndash;&gt;-->
      <!--      <div class="tb" style="background: none"></div>-->
      <!--    </div>-->
      <!-- 底部背景占位 -->
<!--      <div class="jg"></div>-->
<!--      <div class="tb">-->
<!--        <div class="text" style="padding-top: 0.32rem">-->
<!--          {{ newBottomData.ljsdywgdl }}亿度-->
<!--        </div>-->
<!--        <div class="text">售电业务（购售电量）</div>-->
<!--        <img src="../../assets/image/nz1.png" height="70" width="65"/>-->
<!--      </div>-->
      <div class="tb">
        <div class="text" style="padding-top: 0.32rem">
          {{ newBottomData.ljcnfdl }}万度
        </div>
        <div class="text">储能放电量</div>
        <img src="../../assets/image/nz2.png" height="70" width="65"/>
      </div>
    </div>
    <div class="twotb">
      <div class="tb">
        <div class="text" style="padding-top: 0.32rem">
          {{ newBottomData.ljcncfxl }}%
        </div>
        <div class="text">储能电站充放效率</div>
        <img src="../../assets/image/nz3.png" height="70" width="65"/>
      </div>
      <div class="jg"></div>
      <div class="tb">
        <div class="text" style="padding-top: 0.32rem">
          {{ newBottomData.ljywlr }}万元
        </div>
        <div class="text">盈利</div>
        <img src="../../assets/image/nz4.png" height="70" width="65"/>
      </div>
    </div>
    <div class="twotb">
      <div class="tb">
        <div class="text" style="padding-top: 0.32rem">
          {{ newBottomData.ljhkje }}万元
        </div>
        <div class="text">回笼资金</div>
        <img src="../../assets/image/nz5.png" height="70" width="65"/>
      </div>
      <div class="jg"></div>

    </div>
    <div style="height: 0.2rem"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      //底部轮播数据
      bottomdatas: [
        {
          bwrl: '',
          xhxy: '',
          ljffdl: '',
          npjfdyxxss: '',
          xtpjxl: '',
          pjtzcb: '',
          pjjszq: '',
          jszq: '',
          jhdwtzcb: '',
          newBottomData: {},
        },
      ],
    }
  },
  components: {
    //在此处注册
  },
  //钩子函数
  mounted () {
    //获取地图中央信息接口
    this.getdtzyxx()
  },
  computed: {},
  methods: {
    //地图中央信息接口
    getdtzyxx () {
      var _this = this
      this.axios
        .get('financial/getMapCenterInformation')
        .then((response) => {
          _this.bottomdatas = response.data.xmjslist
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => (this.loading = false))
      this.axios
        .get('financial/getScrollingData')
        .then(res => {
          this.newBottomData = res.data[0]
          console.log(this.newBottomData)
        })
    },
  },
}
</script>
